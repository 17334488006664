<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.consignment_warehouse_in_plan") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px"
                       v-model:value="data.searchForm.plan"
                       :placeholder="`${$t('warehouse.plan_name')}/${$t('warehouse.in_warehouse_no')}`"
                       allowClear />
            </a-col>
              <a-col>
              <a-input style="width: 250px"
                       v-model:value="data.searchForm.box"
                       :placeholder="`${$t('warehouse.box_no')}/${$t('common.user_defined_box_no')}`"
                       allowClear />
            </a-col>
             <a-col>
            <SearchProduct ref="refSearchProduct"></SearchProduct>
           </a-col>
            <a-col>
              <a-select v-model:value="data.searchForm.warehouseId"
                        :placeholder="$t('warehouse.target_warehouse')"
                        allow-clear
                        :showSearch="true"
                        optionFilterProp="search-key"
                        style="width: 250px">
                <a-select-option v-for="(value, key) in targetWarehouseList"
                                 :key="key"
                                 :value="value.id"
                                 :title="`${value.warehouseNo}(${value.warehouseName})`"
                                 :search-key="value.warehouseNo + value.warehouseName">
                  {{value.warehouseNo}}({{value.warehouseName}}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="data.searchForm.inWarehouseType"
                        :placeholder="$t('warehouse.in_warehouse_type')"
                        allow-clear
                        style="width: 250px">
                <a-select-option v-for="(value, key) in inWarehouseTypeEnum"
                                 :key="key"
                                 :value="value"
                                 :title="$t($enumLangkey('inWarehouseType', value))">
                  {{ $t($enumLangkey("inWarehouseType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="data.searchForm.inWarehouseStatus"
                        :placeholder="$t('warehouse.status')"
                        allow-clear
                        style="width: 250px">
                <a-select-option v-for="(value, key) in inWarehouseStatusEnum"
                                 :key="key"
                                 :value="value"
                                 :title="$t($enumLangkey('inWarehouseStatus', value))">
                  {{ $t($enumLangkey("inWarehouseStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-range-picker format="YYYY-MM-DD"
                              style="width: 250px"
                              @change="handleConfirmDate" />
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleChangeStatus">{{ $t('common.query') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="ghost" :loading="exportLoading" @click="handlerExport">{{ $t('common.export') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary"
                    @click="handleCreateInPlan"
                    ghost>{{ $t("common.create") }}</a-button>
        </a-col>
      </a-row>

      <div class="inventory-table mt-2">
        <a-table :columns="columns"
                 size="small"
                 :data-source="data.planList"
                 :scroll="{ x: 400, y: wrap.contentHeight - 170 }"
                 :pagination="false"
                 :loading="planListLoading"
                 class="product-table mt-3">
          <template #planNameAndNo>
            <div>{{ $t("warehouse.plan_name") }}</div>
            <div>{{ $t("warehouse.in_warehouse_no") }}</div>
          </template>

          <template #targetWarehouse>
            <span>
              {{ $t("warehouse.target_warehouse") }}
            </span>
          </template>

          <template #inWarehouseType>
            <span>
              {{ $t("warehouse.in_warehouse_type") }}
            </span>
          </template>
          <template #associatedInfo>
            <span>
              {{ $t("warehouse.relevance_info") }}
            </span>
          </template>
          <template #plannedQuantity>
            <span>
              {{ $t("warehouse.planned_quantity") }}
            </span>
          </template>
          <template #plannedBoxQuantity>
            <span>
              {{ $t("warehouse.planned_box_quantity") }}
            </span>
          </template>
          <template #productQuantity>
            <span>
              {{ $t("warehouse.product_quantity") }}
            </span>
          </template>
          <template #receiveBoxQuantity>
            <span>
              {{ $t("warehouse.receive_box_quantity") }}
            </span>
          </template>
          <template #inPlanStatus>
            <span>
              {{ $t("warehouse.status") }}
            </span>
          </template>
          <template #creationTime>
            <span>
              {{ $t("warehouse.creation_time") }}
            </span>
          </template>
          <template #updateTime>
            <span>
              {{ $t("warehouse.update_time") }}
            </span>
          </template>
          <template #subsequentStepsTitle>
            <span>
              {{ $t("warehouse.subsequent_steps") }}
            </span>
          </template>
          <template #relation>
            <div>{{ $t("warehouse.source_related_doc_no") }}</div>
            <div>{{ $t("warehouse.target_related_doc_no") }}</div>
          </template>
          <template #relationV="{ record }">
            <div><CPlanNumberItem :no="record.relation"></CPlanNumberItem></div>
            <div><CPlanNumberItem :no="record.toRelationPlanNo"></CPlanNumberItem></div>
          </template>
          <template #planNameAndNoCustom="{ record }">
            <div>{{ record.planName }}</div>
            <CPlanNumberItem :no="record.planNo"></CPlanNumberItem>
          </template>

          <template #inWarehouseTypeCustom="{ record }">
            <span>
              {{ $t($enumLangkey("inWarehouseType", record.inWarehouseType)) }}
            </span>
          </template>

          <template #planStatusCustom="{ record }">
            {{ $t($enumLangkey("inWarehouseStatus", record.inPlanStatus)) }}
            <template v-if="record.hasException">
              <a-tag color="default" v-if="record.inPlanStatus === inWarehouseStatusEnum.complete || record.inPlanStatus === inWarehouseStatusEnum.cancel">
                {{ $t("common.exception") }}
              </a-tag>
              <a-tag color="error" v-else>{{ $t("common.exception") }}</a-tag>
            </template>
          </template>

          <template #creationTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>
          <template #updateTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.updateTime) }}
          </template>

          <template #operate="{ record }">
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record" v-if="!record.isConfirm">
                    {{ $t("warehouse.dispose_in_warehouse_plan") }}
                  </a-menu-item>
                  <a-menu-item key="2" :record="record" v-else-if="!record.isDeliver">
                    {{ $t("warehouse.send_shipping_in_warehouse_plan") }}
                  </a-menu-item>
                  <a-menu-item key="3" :record="record" v-else-if="record.isNeedUserOperation">
                    {{ $t("warehouse.need_operate") }}
                  </a-menu-item>
                  <a-menu-item key="4" :record="record" v-else>
                    {{ $t("warehouse.plan_details") }}
                  </a-menu-item>
                  <a-menu-item key="5" :record="record" v-if="record.isCanCancel">
                    {{ $t("common.cancel") }}
                  </a-menu-item>
                  <a-menu-item key="6" :record="record" :disabled="!record.isCanCreateCws">
                    {{ $t("menu.transport_create_plan") }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                {{ $t('common.operation') }}
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </template>
        </a-table>
      </div>
      <CPlanCanceledAfterModal ref="cPlanCanceledAfterModalRef" />
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="data.pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onActivated, ref } from "vue";
import { Row, Col, Tag, Table, Select, Button, Form, Space, Input, DatePicker, Dropdown, Menu, Modal, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CPlanCanceledAfterModal from "@/views/components/CPlanCanceledAfterModal.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc, dateString } from '@/utils/general';
import { downloadFile } from '@/utils/downloader.js';
import { getBetweenWarehouse, exportDelegateInWarehousePlan } from "@/api/modules/storage/index";
import { getListPaged, updateCancelPlan } from '@/api/modules/consignment/inbound.js';
import { createTransportPlanByUnNeedProduct } from '@/api/modules/transportation/index.js';
import {
  inWarehouseStatus as inWarehouseStatusEnum,
  inWarehouseType as inWarehouseTypeEnum,
  warehouseType as warehouseTypeEnum,
} from "@/enum/enum.json";
import SearchProduct from "../../components/SearchProduct.vue";

export default defineComponent({
  name: "consignment_inbound_list",
  components: {
    SearchProduct,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    ASelect: Select,
    AForm: Form,
    ATag: Tag,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    ASpace: Space,
    ARangePicker: DatePicker.RangePicker,
    AInput: Input,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CPlanNumberItem,
    CPlanCanceledAfterModal,
  },
  setup () {
    //#region columns
    const columns = [
      {
        slots: {
          title: "planNameAndNo",
          customRender: "planNameAndNoCustom",
        },
        fixed: "left",
        width: 200,
      },
      {
        slots: {
          title: "relation",
          customRender: "relationV",
        },
        width: 150,
      },
      {
        dataIndex: "targetWarehouse",
        slots: {
          title: "targetWarehouse",
        },
        width: 120,
      },
      {
        dataIndex: "inWarehouseType",
        slots: {
          title: "inWarehouseType",
          customRender: "inWarehouseTypeCustom",
        },
        width: 120,
      },
      {
        dataIndex: "plannedQuantity",
        slots: {
          title: "plannedQuantity",
        },
        width: 120,
      },
      {
        dataIndex: "plannedBoxQuantity",
        slots: {
          title: "plannedBoxQuantity",
        },
        width: 120,
      },
      {
        dataIndex: "productQuantity",
        slots: {
          title: "productQuantity",
        },
        width: 120,
      },
      {
        dataIndex: "receiveBoxQuantity",
        slots: {
          title: "receiveBoxQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "inPlanStatus",
        slots: {
          title: "inPlanStatus",
          customRender: "planStatusCustom",
        },
        width: 150,
      },
      {
        dataIndex: "creationTime",
        slots: {
          title: "creationTime",
          customRender: "creationTimeCustom",
        },
        width: 150,
      },
      {
        dataIndex: "updateTime",
        slots: {
          title: "updateTime",
          customRender: "updateTimeCustom",
        },
        width: 150,
      },
      {
        dataIndex: "subsequentSteps",
        slots: {
          title: "subsequentStepsTitle",
          customRender: "operate",
        },
        fixed: "right",
        width: 150,
      },
    ];
    //#endregion
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });
    const refSearchProduct = ref();

    const cPlanCanceledAfterModalRef = ref(null);

    const state = reactive({
      inWarehouseTypeEnum,
      planListLoading: false,
      targetWarehouseList: [],
      exportLoading: false,
    });

    const data = reactive({
      searchForm: {
        plan: null,
        warehouseId: null,
        inWarehouseStatus: null,
        inWarehouseType: null,
        beginCreationTime: null,
        endCreationTime: null,
        box:null
      },
      searchFormCache: {},
      planList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const handlerExport = () => {
      state.exportLoading = true
      let url = exportDelegateInWarehousePlan();
      const searchState = Object.assign({}, data.searchFormCache)
      downloadFile(url, `ds_inbound_plan_${dateString()}.csv`, "POST", searchState).then(() => {
        message.success(vueI18n.t("common.succeed"))
      }).catch(() => { }).finally(() => {
        state.exportLoading = false
      })
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getList();
    };

    const initData = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      getList();
    }

    const handleChangeStatus = () => {
      Object.assign(data.searchFormCache, data.searchForm)
      initData();
    };

    const getList = () => {
      state.planListLoading = true;
      data.searchFormCache.productId= refSearchProduct.value.selectedProductId;
      const searchData = Object.assign({}, data.pageData, data.searchFormCache, {
        warehouseType: warehouseTypeEnum.consignmentWarehouse,
      })
      getListPaged(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.planList = items.map((x) => {
              return {
                key: x.id,
                planName: x.planName,
                planNo: x.planNo,
                relation: x.relation,
                toRelationPlanNo: x.toRelationPlanNo,
                targetWarehouse: x.toInWarehouseNo,
                inWarehouseType: x.inWarehouseType,
                associatedInfo: x.relation ?? "-",
                plannedQuantity: x.total.plan.totalWarehouseCount,
                plannedBoxQuantity: x.total.plan.totalContainerBoxCount,
                productQuantity: x.total.plan.totalCount,
                receiveBoxQuantity: x.total.check.totalContainerBoxCount,
                inPlanStatus: x.inWarehouseStatus,
                creationTime: x.creationTime,
                updateTime: x.lastModificationTime,
                hasException: x.hasException,
                isNeedUserOperation: x.isNeedUserOperation,
                warehouseType: x.warehouseType,
                isDeliver: x.isDeliver, //计划是否已发运
                isConfirm: x.isConfirm, //计划是否已确认
                isCanCancel: x.isCanCancel, //计划是否可以取消
                isCanCreateCws: x.isCanCreateCws, // 是否可以创建运输计划
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
        })
        .finally(() => {
          state.planListLoading = false;
        });
    }

    const getTargetWarehouseList = () => {
      getBetweenWarehouse({ isActive: true, warehouseType: '2' }).then((res) => {
        let { result } = res
        if (result) {
          state.targetWarehouseList = result
        }
      }).catch()
    }

    const handleCreateInPlan = async () => {
      router.push({ path: "/consignment/inbound/create" });
    };

    const handleConfirmDate = (e) => {
      let beginCreationTime = null
      let endCreationTime = null
      if (Array.isArray(e) && e.length === 2) {
        beginCreationTime = currentTimeToUtc(e[0]?.format('YYYY-MM-DD 00:00:00'))
        endCreationTime = currentTimeToUtc(e[1]?.format('YYYY-MM-DD 00:00:00'))
      }
      data.searchForm.beginCreationTime = beginCreationTime
      data.searchForm.endCreationTime = endCreationTime
    }

    const handleCreateTransport = (record) => {
      let loading = message.loading(vueI18n.t('loading...', 0));
      const data = {
        planId: record.key,
        warehouseType: warehouseTypeEnum.consignmentWarehouse
      };
      createTransportPlanByUnNeedProduct(data).then(() => {
        message.success(vueI18n.t('common.succeed'));
        getList();
      }).catch(() => {}).finally(() => {
        loading();
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          router.push(`/consignment/inbound/confirm/${record.key}`)
          break;
        case '2':
          router.push(`/consignment/inbound/shipping/${record.key}`)
          break;
        case '3':
          router.push(`/consignment/inbound/detail/${record.key}`)
          break;
        case '4':
          router.push(`/consignment/inbound/detail/${record.key}`)
          break;
        case '5':
          Modal.confirm({
            title: vueI18n.t("common.operation"),
            content: vueI18n.t("common.are_you_sure_cancel"),
            okText: vueI18n.t("common.confirm"),
            cancelText: vueI18n.t("common.cancel"),
            onOk: () => {
              const cancelData = {
                planId: record.key,
                warehouseType: record.warehouseType,
              }
              return updateCancelPlan(cancelData).then(({ result }) => {
                message.success(vueI18n.t("common.successfully_canceled"));
                handleChangeStatus();
                if (result) {
                  cPlanCanceledAfterModalRef.value.open({
                    name: 'menu.transport_plan',
                    link: '/transport/plandetails/' + result
                  });
                }
              });
            },
          });
          break;
        case '6':
          handleCreateTransport(record);
          break;
        default:
          break;
      }
    }

    onActivated(() => {
      getTargetWarehouseList()
      initData()
    });

    return {
      refSearchProduct,
      columns,
      ...toRefs(state),
      data,
      inWarehouseStatusEnum,
      cPlanCanceledAfterModalRef,

      handlerExport,
      handlePage,
      handleCreateInPlan,
      handleChangeStatus,
      handleConfirmDate,
      handleMenuClick,
    };
  },
})
</script>

<style lang="less" scoped>
</style>