<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('common.hint')"
    :centered="true"
    :mask-closable="false"
    :cancelText="$t('common.not_processed')"
    :okText="$t('common.to_deal_with')"
    @ok="handleDoIt"
    @cancel="close"
  >
    <div class="tips-title">
      <span>{{ $t('common.the_plan_has_an_associated_p0_does_it_need_to_be_processed', [$t(name)]) }}</span>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { Modal } from "ant-design-vue";
import { useRouter } from 'vue-router';

export default defineComponent({
  expose: ["open"],
  emits: ['close'],
  components: {
    AModal: Modal,
  },
  setup(props, { emit }) {
    const router = useRouter();

    const state = reactive({
      visible: false,
      name: '',
      link: '',
    });

    const handleDoIt = () => {
      router.push(state.link);
      state.visible = false;
    };

    const open = ({ name = '', link = '' }) => {
      state.visible = true;
      state.name = name;
      state.link = link;
    };

    const close = () => {
      emit('close');
      state.visible = false;
    };

    return {
      ...toRefs(state),
      handleDoIt,
      open,
      close,
    };
  },
});
</script>

<style scoped>
.tips-title {
  padding: 30px;
}

.tips-title span {
  text-align: center;
}
</style>
